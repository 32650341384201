import request from '@http';

// 2022/11/25 王江毅 活动列表
export function getAticityList(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/promotion/web/page',
        params,
    });
}
// 2022/11/25 王江毅 添加活动
export function saveAticity(data) {
    return request({
        method: 'POST',
        url: '/api/gzf/promotion/web/save',
        data,
    });
}
// 2022/11/25 王江毅 作废活动
export function aticityCancellation(uuid,ticketCancellation) {
    return request({
        method: 'GET',
        url: `/api/gzf/aticity/cancellation/${uuid}?ticketCancellation=${ticketCancellation}`,
    });
}

// 2022/11/25 王江毅 退房时查询我的优惠券
export function getAticityTicketMe(uuid, reasonCode) {
    return request({
        method: 'GET',
        url: `/api/gzf/aticity/ticket/me/${uuid}?reasonCode=${reasonCode}`,
    });
}

// 2022/12/20 王江毅 活动上传附件
export function uploadAccessoryFile(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/aticity/accessoryFile`,
        params
    });
}

// 2023-7-27 王江毅 活动查询详情
export function getAticityInfo(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/promotion/web/info`,
        params
    });
}

// 2023-7-27 王江毅 添加活动
export function updateAticity(data) {
    return request({
        method: 'PUT',
        url: '/api/gzf/promotion/web/update',
        data,
    });
}
// 2023-7-27 王江毅 优惠券列表
export function getCouponPageList(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/coupon/management/web/page',
        params,
    });
}
// 2023-7-27 王江毅 活动列表不带分页
export function getPromotionList(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/promotion/web/list',
        params,
    });
}
// 2023-7-27 王江毅 添加优惠券
export function saveCoupon(data) {
    return request({
        method: 'POST',
        url: '/api/gzf/coupon/management/web/save',
        data,
    });
}
// 2023-7-27 王江毅 修改优惠券
export function updateCoupon(data) {
    return request({
        method: 'PUT',
        url: '/api/gzf/coupon/management/web/update',
        data,
    });
}
// 2023-7-28 王江毅 优惠券详情
export function getCouponInfo(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/coupon/management/web/info`,
        params
    });
}

// 2023-7-28 王江毅 结束活动
export function finishedAticity(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/promotion/web/finished`,
        params
    });
}

// 2023-7-28 王江毅 结束优惠券
export function finishedCoupon(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/coupon/management/web/finished`,
        params
    });
}

// 2023-8-3 张晓瑜 查询已下发的优惠券列表信息
export function getLssueCouponsListAPI(params){
    return request({
        method:'GET',
        url: `/api/gzf/coupon/web/page`,
        params
    })
}